import React from "react"
import { graphql } from "gatsby"
import { FluidObject } from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/Meta/seo"
import { PhotoSliceList } from "../components/PhotoSliceList"
import { ContentWrapper } from "../components/ContentWrapper"
import { getPhotography } from "../fragments/photography"
import { PhotoMeta } from "../data/types/interfaces"

interface Props {
  data: {
    markdownRemark: {
      html: string
    }
    allPhotographyMetaYaml: {
      nodes: PhotoMeta[]
    }
    allImageSharp: {
      nodes: { fluid: FluidObject }[]
    }
  }
}

const PhotographyPage: React.FC<Props> = ({ data }) => {
  const photography = getPhotography()

  return (
    <Layout>
      <SEO
        title="Photography"
        description="My photography mainly focuses on urban landscape, candid portraits and the gentrification of the city."
      />
      <ContentWrapper noMarginTop={true}>
        <PhotoSliceList
          photography={photography}
          photoMeta={data.allPhotographyMetaYaml.nodes}
        />
      </ContentWrapper>
      <ContentWrapper>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </ContentWrapper>
    </Layout>
  )
}

export const query = graphql`
  query PhotgraphyQuery {
    markdownRemark(frontmatter: { slug: { eq: "photography" } }) {
      html
    }
    allPhotographyMetaYaml {
      nodes {
        key
        caption
        alt
        id
      }
    }
  }
`

export default PhotographyPage
