import React from "react"
import Img, { FluidObject } from "gatsby-image"
import { Slice, Caption } from "./index.style"
import { PhotoMeta } from "../../data/types/interfaces"
import { Style } from "../../style"

interface Props {
  photoMeta: PhotoMeta[]
  photography: {
    [key: string]: { fluid: FluidObject }
  }[]
}

const PhotoSliceList: React.FC<Props> = ({ photography, photoMeta }) => {
  const makePhotoSlices = () => {
    return photoMeta.map(meta => {
      const photo = photography[meta.key]

      return (
        <Slice key={meta.id} data-testid="photoSlice">
          <Img
            fluid={photo.childImageSharp.fluid}
            alt={meta.alt}
            fadeIn={false}
            backgroundColor={Style.color.brand}
            loading="eager"
          />
          <Caption data-testid="caption">{meta.caption}</Caption>
        </Slice>
      )
    })
  }

  return <>{makePhotoSlices()}</>
}

export { PhotoSliceList }
