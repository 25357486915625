import { graphql, useStaticQuery } from "gatsby"

const getPhotography = () => {
  const imageData = useStaticQuery(
    graphql`
      fragment FluidImage on File {
        childImageSharp {
          fluid {
            aspectRatio
            src
            srcSet
            sizes
            srcWebp
            srcSetWebp
          }
        }
      }
      query {
        tentStreet: file(relativePath: { eq: "tent-on-street.jpeg" }) {
          ...FluidImage
        }
        manBuilding: file(relativePath: { eq: "man-next-to-building.jpeg" }) {
          ...FluidImage
        }
        boyInSkatePark: file(relativePath: { eq: "boy-in-skate-park.jpeg" }) {
          ...FluidImage
        }
        blowUpThings: file(relativePath: { eq: "blow-up-things.jpeg" }) {
          ...FluidImage
        }
        redLight: file(relativePath: { eq: "red-light.jpeg" }) {
          ...FluidImage
        }
        craneBuilding: file(relativePath: { eq: "crane-building.jpeg" }) {
          ...FluidImage
        }
        dogBusker: file(relativePath: { eq: "dog-and-busker.jpeg" }) {
          ...FluidImage
        }
        clownSausages: file(relativePath: { eq: "clown-sausages.jpeg" }) {
          ...FluidImage
        }
      }
    `
  )
  return imageData
}

export { getPhotography }
