import styled from "styled-components"
import { Style } from "../../style"

const Slice = styled.figure`
  margin-left: auto;
  margin-right: auto;
  max-width: ${Style.layout.maxWidth.main}px;
  margin-bottom: ${Style.megaClamp(4, 8)};
`

const Caption = styled.figcaption`
  font-size: ${Style.megaClamp(1.5, 2)};
  line-height: ${Style.megaClamp(2.5, 3)};
  margin-top: ${Style.megaClamp(1, 2)};
  font-family: ${Style.font.family.main};
  color: ${Style.color.foreground};
  font-weight: ${Style.font.weight.normal};
}
`

export { Slice, Caption }
